<template>
  <div class="analysisForm">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="92px"
    >
      <el-form-item label="线路名称：" prop="lineId">
        <lineSelect @getData="getLineData"></lineSelect>
      </el-form-item>
      <!-- 选择时间： -->
      <el-form-item label="选择时间：" prop="time">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label-width="10px">
        <el-button type="primary" size="small" @click="onSearch()"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 查询form组件
 */
import {
  formatDate,
  checkTimeLimitDay
} from '@/common/utils/index'
import lineSelect from '@/components/lineSelect/lineSelect.vue'

export default {
  components: {
    lineSelect
  },
  props: {
    timeLimit: {
      type: Number,
      default: 31
    },
  },

  data () {
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      form: {
        time: [new Date(), new Date()],
        lineId: null,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date() - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date() - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        time: [{ required: true, message: "请选择时间", trigger: "change" }, { validator: validateDate1, trigger: 'change' }],
        lineId: [{ required: true, message: "请选择线路", trigger: "change" }],
      },
    }
  },

  methods: {
    // 模糊搜索线路
    getLineData (val) {
      this.form.lineId = val
    },

    onSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          if (this.form.time) {
            data.startTime = `${formatDate(this.form.time[0]).split(" ")[0]} 00:00:00`
            data.endTime = `${formatDate(this.form.time[1]).split(" ")[0]} 23:59:59`
          }
          delete data.time
          this.$emit('getFormVal', data)
        }
      })
    },

  },
  created () {
  },
  mounted () { }
}
</script>
